<template>
  <component
    :is="component"
    :feedContent="feedContent"
    :type="type"
    :layout="layout"
    :error="error"
  ></component>
</template>

<script>
import remotecontrol from "@/utils/pusher";
import FeedService from "@/services/FeedService";

export default {
  name: "App",
  components: {
    Loading: () => import("@/views/Loading"),
    Error: () => import("@/views/Error"),
    FeedContent: () => import("@/views/feed/FeedContent"),
    SocialSlide: () => import("@/components/socialslide/SocialSlide"),
    QueueManagerFeed: () =>
      import("@/components/queuemanager/QueueManagerFeed"),
  },
  props: {
    id: String,
  },
  data() {
    return {
      loaded: false,
      type: null,
      feedContent: null,
      layout: null,
      orientation: 0,
      error: {
        hasError: false,
        message: null,
        description: null,
      },
      pusher: null,
    };
  },
  created() {
    if (window.innerWidth < window.innerHeight) {
      this.orientation = 1;
    }
  },
  mounted() {
    this.init().finally(() => {
      this.loaded = true;
    });

    remotecontrol.init();
    remotecontrol.subscribe(this.id);
    remotecontrol.listen(this.id, "update", () => {
      window.location.reload();
    });
  },
  destroyed() {
    remotecontrol.dispose();
  },
  computed: {
    component: function() {
      if (this.error.hasError) {
        return "Error";
      }

      if (this.loaded) {
        switch (this.type) {
          case 0:
            return "SocialSlide";
          case 29:
            return "QueueManagerFeed";
          default:
            return "FeedContent";
        }
      }
      return "Loading";
    },
  },
  methods: {
    init: function() {
      return FeedService.getFeed(this.id)
        .then((result) => {
          this.error.hasError = false;
          this.type = result.data.type;

          var syncC = this.syncContent();
          var syncL = this.syncLayout();
          return Promise.all([syncC, syncL]).then(() => {
            //
          });
        })
        .catch((error) => {
          this.feedContent = null;
          this.type = null;

          this.error.hasError = true;
          this.error.message = "Si è verificato un errore";
          var statusCode = -1;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            statusCode = error.response.status;
            if (statusCode === 401) {
              this.error.message = "Licenza scaduta, contattaci";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            statusCode = 0;
            this.reconnectionTimer = setTimeout(this.init, 30000);
          } else {
            // Something happened in setting up the request that triggered an Error
            statusCode = -1;
            console.log(error);
          }
          this.error.description = `Codice errore: ${statusCode}`;
        });
    },
    syncLayout: function() {
      return FeedService.getLayout(this.id, this.type, this.orientation).then(
        (result) => {
          this.layout = result.data;
          this.layout.skeleton = false;
          return result.data;
        }
      );
    },
    syncContent: function() {
      return FeedService.getContent(this.id, this.type).then((result) => {
        this.feedContent = result.data;
        return result.data;
      });
    },
  },
};
</script>
