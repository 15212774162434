import axios from "axios";

export default {
  getFeed: (id) => {
    return axios.get(`/feed/${id}`);
  },
  getContent: (id, type) => {
    return axios.get(`/feed/${id}/content/${type}`);
  },
  getLayout: (id, type, orientation) => {
    return axios.get(`/feed/${id}/layout/${type}?orientation=${orientation}`);
  },
};
